import React from "react";
import {
  Link, Redirect
} from 'react-router-dom';
import Loader from 'react-loader-spinner'

class Works extends React.Component {
  constructor () {
    super();
    this.state = {
    }
  }
  
  componentDidMount () {

  }

  render () {

    return (
    	<div className="" style={{width:window.innerWidth, height:window.innerHeight}}>
    		                  <div style={{margin:"0px auto"}}>
                  
                  <div><img src="fruit-03.png" style={{width:"65%", padding: "8%", paddingTop:"1%"}} /></div>
                  <div><a style={{width:"65%", padding: "8%"}} href="https://www.evernote.com/shard/s673/client/snv?noteGuid=b21981f1-c6d0-4093-8b23-3b27d23a2474&noteKey=a0110ac67b81f03c3337a1d3efca7bd2&sn=https%3A%2F%2Fwww.evernote.com%2Fshard%2Fs673%2Fsh%2Fb21981f1-c6d0-4093-8b23-3b27d23a2474%2Fa0110ac67b81f03c3337a1d3efca7bd2&title=daddy.app" target="_blank" className="outside">↗︎ notes </a></div><br />

                  </div>
      	</div>
    );
  }
}

export default Works;