import React from "react";
import {
  Link, Redirect
} from 'react-router-dom';
import Loader from 'react-loader-spinner'

class GameBlog extends React.Component {
  constructor () {
    super();
    this.state = {
    }
  }
  
  componentDidMount () {

  }

  render () {

    return (
    	<div className="" style={{width:window.innerWidth, height:window.innerHeight}}>
    		                  <div style={{margin:"0px auto"}}>
                  <p className="main_p">Blog for Socially Engaged Games</p>
                  
                  <p className="main_p">
                  <p className="desc_title">091119 My goal for the class</p>
                  <p className="desc_p">I want to create a game about regretful moments in human memories. It’s interesting since people think they may change their present by making different choices in the past. It seems like they are treating those choices like variables in programs and then hope it can change the result of some functions. I’d like to engage more physical materials for this course since I have usually worked with virtual elements in the past projects and feel a bit tired of the interactions happening in the virtual world. But I have few fabrication skills so first I need to figure out how far i can achieve if i want to build a game in the physical world. 
                  </p>
                  </p>
                  
                  <div><img src="fruit-03.png" style={{width:"65%", padding: "8%", paddingTop:"1%"}} /></div>
                  <div><a style={{width:"65%", padding: "8%"}} href="https://docs.google.com/document/d/1OE1BUrcPlyuCXSZnUnrujAtJGAwzTs-h3LjNTBL5oHY/edit?usp=sharing" target="_blank" className="outside">↗︎ game concept 1 </a></div><br />
                  <div><img src="social_game-01.png" style={{width:"65%", padding: "8%", paddingTop:"1%"}} /></div>
                  <div><a style={{width:"65%", padding: "8%"}} href="https://drive.google.com/file/d/1BzCnPAZI-myhKJYfHSwrqN9RJu4HzJFs/view?usp=sharing" target="_blank" className="outside">↗︎ sandspiel<br /><img src="datapng.png" style={{padding: "8%", paddingTop:"1%"}} /> </a></div><br />
                  </div>
      	</div>
    );
  }
}

export default GameBlog;