import React, { Component , Fragment} from 'react';
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom';
import './App.css';

import createClass from 'create-react-class';

import OpenCall from './components/OpenCall';
import GameBlog from './components/game-blog';
import Works from './components/Works';
import TorrentsOfSex from './components/TorrentsOfSex';

const a = [1, 10, 100, 1000, 10000];
var App = createClass({
  openIntro:function(){
    var introDiv = document.getElementById("intro");
    introDiv.style.display = "inherit";
    var editButton = document.getElementById("edit");
    editButton.style.display = "none";
    var cvButton = document.getElementById("cv");
    cvButton.style.display = "none";
  },
  closeIntro:function(){
    var introDiv = document.getElementById("intro");
    introDiv.style.display = "none";
    var editButton = document.getElementById("edit");
    editButton.style.display = "inherit";
    var cvButton = document.getElementById("cv");
    cvButton.style.display = "inherit";
  },
    render: function () {
    return (
      <Router>
        <div id="app" className="App" style={{margin:"0px auto", height:"100%"}}>
          <Fragment>
              <Switch>
              <Route exact path="/" exact render={() => (

                  <div style={{margin:"0px auto", height:"100%"}}>
                  <div id="shade_div"></div>
                  <div id="shade_ascii_div"></div>
<div style={{position:"relative",borderBottom:"1px solid black",height:"100%"}}>
<canvas id="canvas" resize="true"></canvas>
<img src="handshake_erotica_s.png" id="handshakeErotica" className="maskedImg" />
<img src="web_l-02_s.png" id="torrentsOfSex" className="maskedImg" />
<img src="daddy_illustration_tan_s-01_s.png" id="daddyResidency" className="maskedImg" />
<img src="instant_baby_s.png" id="instantBaby" className="maskedImg" />
<img src="flowchart_s.png" id="lubricating" className="maskedImg" />
<img src="sos_webbsite.png" id="shapeofsex" className="maskedImg" />
<img src="tactics3_s.png" id="tactics" className="maskedImg" />

    </div>
    <div id="edit" onClick={(e)=>this.openIntro(e)}>
    edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit edit 
    </div>
    <a id="cv" href="naheekim__cv_2020.pdf" target="_blank">
    openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV openCV 
    </a>
    <div>hi</div>
<div id="intro" onClick={(e)=>this.closeIntro(e)}>
<div dangerouslySetInnerHTML={{__html:"import NaheeApp from ‘nahee’<br><br>var App = NaheeApp.createPortfolio({<br>getIntroduction: function () {<br><br>/*<br>Nahee Kim(she/they) is an artist, teacher, and web programmer who is performing “nahee.app” in social media. “nahee.app” was started from pseudo programs which interpret Nahee’s sexual identity, preference, and experiences as algorithms. “nahee.app” creates sex docs by tweaking existing network protocols’ communication mechanism into provocative interactions, speculative sex toys and matching applications. For now running “Daddy Residency” which is an experiment to seek new approaches of building family. Nahee is based in New York and Seoul.<br/>*/<br><br>},"}} >
    </div>
<div id="back" onClick={(e)=>this.closeIntro(e)}>
✕✕✕✕✕✕✕✕✕✕✕✕✕✕✕✕✕✕✕
</div>
<a href="https://www.instagram.com/nahee.app/" id="insta" target="_blank">
instagram
</a>
<a href="https://github.com/nahbee10/nahee.app" id="github" target="_blank">
github
</a>
</div>

                  </div>
                )} />
              <Route exact path="/torrentsOfSex" exact render={() => (

                  <TorrentsOfSex></TorrentsOfSex>

                )} />
              <Route exact path="/shellhouse" exact render={() => (

                  <div>
                  <img src="babycounter.png" style={{width:"100%"}} />
                  </div>

                )} />
              <Route exact path="/torrents_of_sex" exact render={() => (

                  <div><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://lapses-screening.s3.amazonaws.com/torrents_of_sex.pdf" style={{width:"100%", margin:"36px"}} >Download</a>
                  </div>

                )} />
              <Route exact path="/game-blog" exact render={() => (

                  <GameBlog></GameBlog>

                )} />
              <Route exact path="/works" exact render={() => (

                  <Works></Works>

                )} />
              <Route exact path="/" exact render={() => (

                  <OpenCall></OpenCall>

                )} />
                </Switch>
              </Fragment>
            </div>
          </Router>

        )}
})

export default App;
