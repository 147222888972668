import React from "react";
import {
  Link, Redirect
} from 'react-router-dom';
import '../Tos.css';

class TorrentsOfSex extends React.Component {
  constructor () {
    super();
    this.state = {
    }
  }
  
  componentDidMount () {

  }

  render () {

    return (
    	<div className="" style={{width:window.innerWidth, height:window.innerHeight}}>
    		<div className="big_div2">

        <div id="photos_main">
        <img src="web_bgcolor.png" id="main_bg" />
        </div>
        <div id="photos_main2">
        <img src="web_transparency-02.png" id="main_layer" />
        </div>
        <canvas id="canvas" resize="true">
        </canvas>
        
        <div id="table_div">
            <table id="table">
            <tr>
            <th>name</th>
            <th>size</th>
            <th>status</th>
            <th>peers</th>
          </tr>
          <tr onClick="openDesc('project_content2',1)">
            <td>sex.torrent</td>
            <td>50.3MB</td>
            <td>downloading</td>
            <td>3</td>
          </tr>
          <tr onClick="openDesc('project_img_container1',2)">
            <td>sex.torrent</td>
            <td>108KB</td>
            <td>downloading</td>
            <td>9</td>
          </tr>
          <tr>
            <td>sex.torrent</td>
            <td>5.2MB</td>
            <td>stopped</td>
            <td>0</td>
          </tr>
          <tr onClick="openDesc('project_img_container2',4)">
            <td>sex.torrent</td>
            <td>1.3MB</td>
            <td>completed</td>
            <td>4</td>
          </tr>
          <tr>
            <td>sex.torrent</td>
            <td>50.3MB</td>
            <td>ERROR</td>
            <td>1</td>
          </tr>
          <tr onClick="openDesc('project_img_container3',6)">
            <td>sex.torrent</td>
            <td>50.3MB</td>
            <td>completed</td>
            <td>0</td>
          </tr>
        </table>
        </div>
        <div id="desc" onClick="descDismiss()">
        <div id="project_content2">
        <div className="project_title2">Torrents of Sex</div>/*

        Assuming that someday human can upload and download their somatic sensations onto the web, Nahee.app designed peer-to-peer polyamorous sexual relationship network protocol &lt;Torrents of Sex&gt;, inspired by BitTorrent’s file distribution & peer selection algorithms.<br/>
        &lt;Torrents of Sex&gt; was exhibited at Our Networks 2019 and Cybernetics Library @ NY Art Book Fair 2019.
*/
<br/><br/>
</div>

<div id="project_img_container1">
        <img src="web_desc_f1.png" className="project_img"/>
</div>
<div id="project_img_container2">
        <img src="web_desc-f2.png" className="project_img"/>
</div>
<div id="project_img_container3">
        <img src="web_desc_algo.png" className="project_img"/>
</div>


</div>   


    </div>
    <div className="back">
<a href="Tadpoles.html" className="back_link">◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎</a>
</div>
<div id="shade_div"></div>
      	</div>
    );
  }
}

export default TorrentsOfSex;